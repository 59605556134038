import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Row from "react-bootstrap/Row";
import "./ListCard.scss";
import Cards from "../Cards";
import { Container } from "react-bootstrap";
import data from "../../Assets/Data/headingTab.json";
import { useNavigate } from "react-router-dom";

function Listcardtest({ lang, id }) {
  const navigate = useNavigate();
  const handleClick = (num) => {
    if (lang === "vi") {
      navigate(`/vi/${num}`);
    } else if (lang === "en") {
      navigate(`/en/${num}`);
    }
  };
  // const [key, setKey] = useState(1);
  // useEffect(() => {
  //   console.log(key);
  // }, [key]);
  //xs={1} md={2} xxl={8} className="justify-content-center flex-column"
  return (
    //fluid className="listCardContainer"
    <Container fluid className="listCardContainer">
      <Row>
        <Tabs
          defaultActiveKey={id}
          id="justify-tab-example"
          className="tabs"
          onSelect={(k) => handleClick(k)}
          variant="pill"
          fill
        >
          {lang === "vi"
            ? data.headingTab.map((item) => (
              <Tab
                eventKey={item.idVi}
                title={item.vi}
                tabClassName="tabitem"
                key={item.vi}
              >
                <Container>
                  <Cards lang={lang} path={item.path} />
                </Container>
              </Tab>
            ))
            : data.headingTab.map((item) => (
              <Tab
                eventKey={item.idEn}
                title={item.en}
                tabClassName="tabitem"
                key={item.en}
              >
                <Container>
                  <Cards lang={lang} path={item.path} />
                </Container>
              </Tab>
            ))}
        </Tabs>
      </Row>
    </Container>
  );
}

export default Listcardtest;
