import React from "react";
import Navbar from "../Component/Navbar";
import { Routes, Route } from "react-router-dom";

import WarrantyPolicy from "../Pages/warrantyPolicy";
import UserManual from "../Pages/userManual";
import SmarthomeKnowledge from "../Pages/smarthomeKnowledge";
import ConstructionExperience from "../Pages/constructionExperience";
import Faq from "../Pages/faq";

import Footer from "../Component/Footer";
import data from "../Assets/Data/data.json";

import HomecontrollerRoute from "../Component/Route/homecontrollerRoute";
import SwitchlutobuttonRoute from "../Component/Route/switchlutobuttonRoute";
import SwitchbuttonRoute from "../Component/Route/switchbuttonRoute";
import SensorremoteRoute from "../Component/Route/sensorremoteRoute";
import SmartlightingRoute from "../Component/Route/smartlightingRoute";
import LockgateRoute from "../Component/Route/lockgateRoute";
import MultizoneaudioRoute from "../Component/Route/amthanhdavungRoute";
import WorkwithlumiRoute from "../Component/Route/workwithlumiRoute";
import AppLumiLifeRoute from "../Component/Route/applumilifeRoute";
import LumiThingRoute from "../Component/Route/lumiThingRoute";
import SearchedWebview from "../Pages/direct";
import { useParams } from "react-router-dom";

function AppVI({ lang }) {
  let { idVi } = useParams();
  console.log(idVi);
  return (
    <>
      <Navbar lang={`vi`} id={idVi} />
      <Routes>
        <Route path="/" element={<UserManual lang={`vi`} id={idVi} />} />
        <Route
          path="huongdansudung"
          element={<UserManual lang={`vi`} id={idVi} />}
        />
        <Route
          path="chinhsachbaohanh"
          element={<WarrantyPolicy lang={`vi`} />}
        />
        <Route
          path="kinhnghiemthicong"
          element={<ConstructionExperience lang={`vi`} />}
        />
        <Route
          path="kienthucnhathongminh"
          element={<SmarthomeKnowledge lang={`vi`} />}
        />
        <Route path="cauhoithuonggap" element={<Faq lang={`vi`} />} />
        <Route path="Search/:id/:documentUrl" element={<SearchedWebview />} />
      </Routes>
      <HomecontrollerRoute lang={`vi`} src={data.HomeController} idVi={idVi} />
      <SwitchlutobuttonRoute
        lang={`vi`}
        src={data.switchLutoButton}
        idVi={idVi}
      />
      <SwitchbuttonRoute lang={`vi`} src={data.switchButton} idVi={idVi} />
      <SensorremoteRoute
        lang={`vi`}
        src={data.moduleSensorRemote}
        idVi={idVi}
      />
      <SmartlightingRoute lang={`vi`} src={data.smartLighting} idVi={idVi} />
      <LockgateRoute lang={`vi`} src={data.lockGate} idVi={idVi} />
      <MultizoneaudioRoute lang={`vi`} src={data.amThanhDaVung} idVi={idVi} />
      <WorkwithlumiRoute lang={`vi`} src={data.workWithLumi} idVi={idVi} />
      <AppLumiLifeRoute lang={`vi`} src={data.appLumiLife} idVi={idVi} />
      <LumiThingRoute lang={`vi`} src={data.lumiThing} idVi={idVi} />
      <Footer />
    </>
  );
}
export default AppVI;
