import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
function SearchedWebview() {
  const { id, documentUrl } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let url = `https://docs.lumi.vn/share/${id}/doc/${documentUrl}`
  console.log('id', url );
  return (
    <div>
      <iframe
        src={url}
        className="viewheight pt-9"
        title="Webview"
      />
    </div>
  );
};
export default SearchedWebview;
